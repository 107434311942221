<template>
    <div class="container">
        <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="success">
            Sikeresen hozzáaadtad a közleményt, az admin rovatba!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">

            </button>
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="error">
            <div v-for="error in errors.errors">
                <div v-for="msg in error">
                    <li>{{msg}}</li>
                </div>
            </div>
        </div>
        <form @submit="formSubmit">

            <vue-editor v-model="content"></vue-editor>
            <button class="btn btn-success">Submit</button>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';
    import { VueEditor, Quill } from "vue2-editor";

    export default {
        name: "RichTextEditor",
        data() {
            return {
                content: '',
                success: false,
                succ: '',
                error: false,
                errors: []
            }
        },
        methods: {
            formSubmit(e) {
                e.preventDefault();
                let currentObj = this;
                axios.post('/admin/admin_post', {
                    message: this.content,
                }).then((response) => {
                    console.log(response.data);
                    this.success = true;
                }).catch(error => this.errors = error.response.data);
            }
        }
    }
</script>
<style scoped>
</style>
