<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                Sikeresen módosítottad a kategória nevét.
                <br> Új név: <strong>{{ newCategoryName }}</strong>
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{ msg }}
                    </div>
                </div>
            </b-alert>
        </div>

        <b-form-group
            class="mb-0"
            label="ID"
            label-for="input-formatter"
            description="A kategória egyedi azonosítója. Nem módosítható!">
            <b-form-input
                id="input-formatter"
                :placeholder=category_id
                disabled
            ></b-form-input>
        </b-form-group>

        <br>

        <b-form-group
            class="mb-0"
            label="Név"
            label-for="input-formatter"
            description="A kategória neve.">
            <b-form-input
                id="input-formatter"
                :placeholder=category_name
                v-model="name"
            ></b-form-input>
        </b-form-group>
        <br>
        <br>
        <b-button variant="success" style="float:right" v-on:click=editCategory()>Módosítás</b-button>
        <b-button variant="primary" href="/admin/item/categories">Vissza</b-button>
        <div style="margin-bottom:10px"></div>

    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSelectImage from 'vue-select-image'

Vue.use(BootstrapVue)
Vue.use(VueSelectImage)


export default {
    components: {
        VueSelectImage
    },
    props: ['category_id', 'category_name'],
    data() {
        return {
            error: false,
            success: false,
            newCategoryName: null,
            errors: [],
            name: this.category_name,
        }
    },
    methods: {
        editCategory() {
            axios.post('/admin/item/category/update', {
                name: this.name,
                id: this.category_id,
            }).then((response) => {
                this.success = true;
                this.newCategoryName = this.name,
                    this.successresponse = response.data;
            }).catch(error => {
                this.errors = error.response.data;
                this.error = true;
                this.success = false;
            });
            window.scrollTo(0, 0)
        },
    }
}
</script>
