<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                Sikeresen módosítottad a kategória nevét.
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        <li>
                            <strong>
                                {{ msg }}
                            </strong>
                        </li>
                    </div>
                </div>
            </b-alert>
        </div>

        <b-form-group
            class="mb-0"
            label="ID"
            label-for="input-formatter"
            description="A kuponkód egyedi azonosítója. Nem módosítható!">
            <b-form-input
                id="input-formatter"
                :placeholder=coupon_id
                disabled
            ></b-form-input>
        </b-form-group>

        <br>

        <b-form-group
            class="mb-0"
            label="Név"
            label-for="input-formatter"
            description="Kuponkód neve.">
            <b-form-input
                id="input-formatter"
                :placeholder=coupon_name
                v-model="name"
            ></b-form-input>
        </b-form-group>


        <b-form-group
            class="mb-0"
            label="Kód"
            label-for="input-formatter"
            description="A kód, amivel felhasználják.">
            <b-form-input
                id="input-formatter"
                :placeholder=coupon_code
                v-model="code"
            ></b-form-input>
        </b-form-group>
        <br>

        <b-form-group label="Kuponkód típusa">
            <b-form-radio v-model="type" name="coupon_type" value="1">Százalékos</b-form-radio>
            <b-form-radio v-model="type" name="coupon_type" value="2">Pontos értékű</b-form-radio>
        </b-form-group>
        <b-form-group

            class="mb-0"
            label="Kuponkód érvényessége"
            label-for="input-formatter"
            description="Meddig érvényes a kuponkód.">
            <datetime format="YYYY-MM-DD H:i:s" v-model="valid_until"></datetime>
        </b-form-group>d

        <b-form-group
            class="mb-0"
            label="Értéke"
            label-for="input-formatter"
            description="Kuponkód értéke.">
            <b-form-input
                id="input-formatter"
                :placeholder=coupon_value
                v-model="value"
            ></b-form-input>
        </b-form-group>


        <b-form-group label="Kuponkód érvényessége">
            <b-form-radio v-model="used" name="coupon_used" value="0">Érvényes</b-form-radio>
            <b-form-radio v-model="used" name="coupon_used" value="1">Nem érvényes</b-form-radio>

        </b-form-group>


        <br>
        <br>
        <b-button variant="success" style="float:right" v-on:click=editCoupon(coupon_id)>Módosítás</b-button>
        <b-button variant="primary" href="/admin/coupons">Vissza</b-button>
        <div style="margin-bottom:10px"></div>

    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSelectImage from 'vue-select-image'
import datetime from "vuejs-datetimepicker";

Vue.use(BootstrapVue)
Vue.use(VueSelectImage)


export default {
    name: "DateTimePicker",
    components: {
        datetime,
        VueSelectImage,
    },
    props: ['coupon_id', 'coupon_name', 'coupon_used', 'coupon_valid_until', 'coupon_code', 'coupon_value', 'coupon_type'],
    data() {
        return {
            error: false,
            success: false,
            newCategoryName: null,
            errors: [],
            coupon_name: this.coupon_name,
            type: this.coupon_type,
            code: this.coupon_code,
            valid_until: this.coupon_valid_until,
            value: this.coupon_value,
            used: this.coupon_used,
        }
    },
    methods: {
        editCoupon(id) {
            axios.post('/admin/coupon/' + id + '/update', {
                id: this.coupon_id,
                name: this.name,
                type: this.type,
                code: this.code,
                used: this.used,
                valid_until: this.valid_until,
                value: this.value,
            }).then((response) => {
                this.success = true;
                this.newCategoryName = this.name,
                    this.successresponse = response.data;
            }).catch(error => {
                this.errors = error.response.data;
                this.error = true;
                this.success = false;
            });
            window.scrollTo(0, 0)
        },
    }
}
</script>
