<template>
    <b-container fluid>

        <div>
            <b-alert variant="success" v-model="success" dismissible>
                {{successresponse}}
            </b-alert>
            <b-alert variant="danger" v-model="error" dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{msg}}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-row>
            <b-col lg="6" class="my-1">
                <b-form-group
                    label="Rendezés"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-select v-model="sortBy" id="sortBySelect" :options="sortOptions" class="w-75">
                            <template v-slot:first>
                                <option value="">-- semmi --</option>
                            </template>
                        </b-form-select>
                        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                            <option :value="false">Növekvő</option>
                            <option :value="true">Csökkenő</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
                <b-form-group
                    label="Szűrő"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input
                            v-model="filter"
                            type="search"
                            id="filterInput"
                            placeholder=""

                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Tisztítás</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col sm="5" md="6" class="my-1">
                <b-form-group
                    label="Adat per oldal"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0">
                    <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-table
            show-empty
            small
            ref="ref"
            stacked="md"
            :items="users"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered">
            <template v-slot:cell(names)="row">
                {{ row.value.first }} {{ row.value.last }}
            </template>
            <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="clickEdit(row.item.id)" class="mr-1" variant="info">
                    Szerkesztés
                </b-button>
                <b-button size="sm" @click="destroy(row.item.id)" variant="danger">
                    Törlés
                </b-button>
            </template>
            <template v-slot:row-details="row">
                <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                </b-card>
            </template>
        </b-table>
        <b-col sm="7" md="6" class="my-1">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
            ></b-pagination>
        </b-col>
    </b-container>
</template>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'

    Vue.use(BootstrapVue)
    export default {
        data() {
            return {
                users: [],
                fields: [
                    {key: 'id', label: 'id', sortable: true, sortDirection: 'desc'},
                    {key: 'name', label: 'Név', sortable: true, class: 'text-center'},
                    {key: 'formatPrice', label: 'Jelenlegi ár', sortable: true, class: 'text-center'},
                    {key: 'onSale', label: 'Akciós', sortable: true, class: 'int'},
                    {key: 'originalPrice', label: 'Eredeti ár', sortable: true, class: 'int'},
                    {key: 'actions', label: 'Műveletek'}
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15, 20, 50],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                success: false,
                successresponse: "",
                errors: [],
                error: false,

            }
        },
        computed: {
            sortOptions() {
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {text: f.label, value: f.key}
                    })
            }
        },
        mounted() {
            this.fetchUsers();
        },
        methods: {
            fetchUsers() {
                axios
                    .get('/vue/api/admin/items/')
                    .then(response => {
                        this.users = response.data
                        this.totalRows = this.users.length;
                    });
            },
            info(item, index, button) {
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            destroy(id) {
                axios.delete('/admin/item/' + id + '/delete', {}).then((response) => {
                    this.fetchUsers();
                    this.success = true;
                    this.error = false;
                    this.successresponse = response.data.success;
                }).catch(error => {
                    this.errors = error.response.data;
                    this.success = false;
                    this.error = true;
                });
            },
            clickEdit(id) {
                window.location.href = `/admin/item/${id}/edit`
            },
        }
    }
</script>

<style scoped>

</style>
