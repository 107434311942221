<template>
    <datetime format="YYYY-MM-DD H:i:s" v-model=date></datetime>
</template>
<script>
    import datetime from 'vuejs-datetimepicker';

    export default {
        name: "DateTimePicker",
        components: {datetime},
        mounted() {
        }
    }
</script>
<style scoped>
</style>
