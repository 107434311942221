<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                {{successresponse}}
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{msg}}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-form-group
            class="mb-0"
            label="ID"
            label-for="input-formatter"
            description="A felhasználó egyedi azonosítója">
            <b-form-input
                id="input-formatter"
                :placeholder=user_id
                disabled
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Felhasználónév"
            label-for="input-formatter"
            description="A felhasználó neve">
            <b-form-input
                id="input-formatter"
                :placeholder=username
                disabled
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Tiltás ideje "
            label-for="input-formatter"
            description="Meddig legyen tiltva a felhasználó">
            <datetime format="YYYY-MM-DD H:i:s" v-model="date"></datetime>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Indok"
            label-for="input-formatter"
            description="A tiltáshoz tartozó indok">
            <b-form-input
                id="input-formatter"
                v-model="reason"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-button variant="danger" style="float:right" v-on:click=BanUser()>Tiltás</b-button>
        <b-button variant="primary" href="/admin/users/manage">Vissza</b-button>
        <div style="margin-bottom:10px"></div>
    </div>
</template>

<script>
    import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import axios from 'axios';
    import Vue from 'vue';

    import datetime from 'vuejs-datetimepicker';

    Vue.use(BootstrapVue)

    export default {
        name: "DateTimePicker",
        components: {datetime},
        props: ['user_id', 'username'],
        data() {
            return {
                error: false,
                success: false,
                reason: this.reason1,
                banned_until: this.banned_until,
                date: '',
                successresponse: '',
                errors: [],
            }
        },
        methods: {
            BanUser() {
                axios.post('/admin/users/' + this.user_id + '/ban', {
                    reason: this.reason,
                    banned_until: this.date,
                }).then((response) => {
                    this.success = true;
                    this.error = false;
                    this.successresponse = response.data.success;
                }).catch(error => {
                    this.errors = error.response.data;
                    this.success = false;
                    this.error = true;
                });
            },
        }
    }
</script>
