<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                {{successresponse}}
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{msg}}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-form-group
            class="mb-0"
            label="ID"
            label-for="input-formatter"
            description="A felhasználó egyedi azonosítója. Nem módosítható!">
            <b-form-input
                id="input-formatter"
                :placeholder=user_id
                disabled
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Felhasználónév"
            label-for="input-formatter"
            description="A felhasználó neve">
            <b-form-input
                id="input-formatter"
                v-model="user"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="E-mail cím"
            label-for="input-formatter"
            description="E-mail cím módosítása">
            <b-form-input
                id="input-formatter"
                v-model="emailtext"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-button variant="success" style="float:right; margin-left: 5px" v-on:click=EditUser()>Módosítás</b-button>
        <b-button variant="danger" style="float:right" v-on:click=NewPassword()>Új jelszó generálása</b-button>
        <b-button variant="primary" href="/admin/users/manage">Vissza</b-button>
        <div style="margin-bottom:10px"></div>
    </div>
</template>

<script>
    import axios from 'axios';
    import Vue from 'vue';
    import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    Vue.use(BootstrapVue)
    export default {
        props: ['user_id', 'username', 'email'],
        data() {
            return {
                error: false,
                success: false,
                banned_until: this.banned_until,
                date: '',
                successresponse: '',
                errors: [],
                user: this.username,
                emailtext: this.email,
            }
        },
        methods: {
            EditUser() {
                axios.post('/admin/users/' + this.user_id + '/edit', {
                    user_id: this.user_id,
                    email: this.emailtext,
                    name: this.user,
                }).then((response) => {
                    this.success = true;
                    this.successresponse = response.data;
                }).catch(error => {
                    this.errors = error.response.data,
                        this.error = true,
                        this.success = false
                });
                window.scrollTo(0, 0)
            },
            NewPassword() {
              axios.get('/admin/users/' + this.user_id + '/password', {

              }).then((response) => {
                  this.success = true;
                  this.successresponse = response.data;
              })
            },
        }
    }
</script>
