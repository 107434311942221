<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                Sikeresen létrehoztad a <strong>{{ category_name }}</strong> nevű kategóriát.
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{ msg }}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-form-group
            class="mb-0"
            label="Kategória neve"
            label-for="input-formatter"
            description="A kategória neve.">
            <b-form-input
                id="input-formatter"
                v-model="category_name"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-button variant="success" style="float:right; margin-left: 5px" v-on:click=AddCategory()>Kategória
            létrehozása
        </b-button>
        <b-button variant="primary" href="/admin/categories">Vissza</b-button>
        <div style="margin-bottom:10px"></div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
export default {
    data() {
        return {
            error: false,
            success: false,
            errors: [],
            category_name: null,
        }
    },
    methods: {
        AddCategory() {
            axios.post('/admin/item/category/create', {
                name: this.category_name,
            }).then((response) => {
                this.success = true;
                this.successresponse = response.data;
            }).catch(error => {
                this.errors = error.response.data,
                    this.error = true,
                    this.success = false
            });
            window.scrollTo(0, 0)
        },
    }
}
</script>
