<template>
    <div>
        <div>
            <b-alert variant="success" v-model="success" dismissible>
                {{ successresponse }}
            </b-alert>

            <b-alert variant="danger" v-model="error" dismissible>
                <template v-if="errors.message === 'Unauthenticated.'">
                    Jelenleg, csak regisztrált felhasználóknak érhető el ez a funkció!
                    <br>
                    A kívánságlista használatához regisztráció szükséges.
                </template>

                <div v-else v-for="error in errors.errors">
                    {{ error }}
                    <div v-for="msg in error">
                        {{ msg }}
                    </div>
                </div>

                <template>
                    <div  v-if="errors.message !== 'Unauthenticated.'">
                        A kosár használatához regisztráció szükséges.
                    </div>
                </template>
            </b-alert>

        </div>
        <div class="row">
            <div class="col-md-9 single_left">
                <div class="single_image" bis_skin_checked="1">
                    <ul id="etalage">
                        <li>
                            <a href="optionallink.html">
                                <img class="etalage_thumb_image" :src="img"/>
                                <img class="etalage_source_image" :src="img"/>
                            </a>
                        </li>
                        <template v-for="itemImages in images">
                            <li>
                                <img class="etalage_source_image" :src="itemImages.src"/>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="single_right">
                    <h1 class="mb-4"><b>{{ name }}</b></h1>
                    <h3>Rövid leírás</h3>
                    <p style="font-size: 15px">{{ desc }}</p>
                    <ul class="add-to-links mt-5">
                        <b>Műveletek</b>
                        <div v-if="wishlist == 1">
                            <b-form-group>
                                <b-form-checkbox
                                    id="checkbox"
                                    name="checkbox-1"
                                    value="Eltávolítás a kívánságlistádról."
                                    unchecked-value="Hozzáadás a kívánságlistádhoz."
                                    v-model="checked1"
                                    v-on:change="addWishlist()">
                                    {{ checked1 }}
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                        <div v-else>
                            <b-form-group>
                                <b-form-checkbox
                                    id="checkbox"
                                    name="checkbox-2"
                                    value="Eltávolítás a kívánságlistádról."
                                    unchecked-value="Hozzáadás a kívánságlistádhoz."
                                    v-model="checked"
                                    v-on:change="addWishlist()">
                                    {{ checked }}
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </ul>
                </div>
                <div class="clear"></div>
            </div>
            <div class="col-md-3">
                <div class="box-info-product">
                    <input type="hidden" name="user_id" value="">
                    <input type="hidden" name="item_id" value="">
                    <p class="price2">{{ formatPrice(price * value) }} Ft </p>
                    <ul class="prosuct-qty">
                        <span>Mennyiség:</span>
                        <b-form-spinbutton v-model="value" inline name="quantity"></b-form-spinbutton>
                        <div class="mt-3" v-if="check_item == 0">
                            <b-button variant="success" @click="add()" style="font-size:20px"><i
                                class="fas fa-shopping-cart"> Kosárba tesz</i></b-button>
                        </div>
                        <div class="mt-3" v-else>
                            <b-button disabled variant="danger" style="font-size:20px"><i class="fas fa-shopping-cart">A
                                kosaradban van</i></b-button>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <div class="desc">
            <h2><b>Részletes leírás:</b></h2>
            <h5 class="mt-3"><span v-html="details"></span></h5>
            <p></p>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    props: ['price', 'img', 'id', 'name', 'details', 'desc', 'check', 'wishlist', 'images'],
    data() {
        return {
            value: 1,
            success: false,
            errors: [],
            error: false,
            items: [],
            checked: 'Hozzáadás a kívánságlistához.',
            item_id: null,
            checked1: 'Eltávolítás a kívánságlistádról.',
            check_item: this.check,
            successresponse: {},
        }
    },
    mounted() {
        this.fetchImages();
    },
    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        add() {
            axios.post('/item/' + this.id, {
                item_id: this.id,
                quantity: this.value,
            }).then((response) => {
                this.check_item = 1;
                this.status = response.status;
                this.success = true;
                this.error = false;
                this.successresponse = response.data.success;
            }).catch(error => {
                this.errors = error.response.data;
                this.success = false;
                this.error = true;
            });
        },
        addWishlist() {
            axios.post('/item/wishlistadd', {
                item_id: this.id,
            }).then((response) => {
                this.success = true;
                this.error = false;
                this.successresponse = response.data.success;
            }).catch(error => {
                this.errors = error.response.data;
                this.success = false;
                this.error = true;
            });
        },
    },
}
</script>
