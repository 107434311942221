<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                {{successresponse}}
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{msg}}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-form-group
            class="mb-0"
            label="ID"
            label-for="input-formatter"
            description="A tárgy egyedi azonosítója. Nem módosítható!">
            <b-form-input
                id="input-formatter"
                :placeholder=item_id
                disabled
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Név"
            label-for="input-formatter"
            description="A tárgy neve, maximum 150 karakter.">
            <b-form-input
                id="input-formatter"
                v-model="name"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Rövid leírás"
            label-for="input-formatter"
            description="A tárgy rövid leírása. Maximum 1000 karaktert, formázást nem tartalmaz.">
            <b-form-textarea
                rows="4"
                v-model="description"
            ></b-form-textarea>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Hosszú  leírás"
            label-for="input-formatter"
            description="A tárgy hosszú leírása. A szerkesztés engedélyezett.">
            <vue-editor v-model="details"></vue-editor>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Ár"
            label-for="input-formatter"
            description="A tárgy alapértelmezett ára, forintban értendő.">
            <b-form-input
                rows="4"
                v-model="price"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Akciós ár"
            label-for="input-formatter"
            description="A tárgy akciós ára. Használata: Amennyiben, az értéke több mint 0, a tárgy automatikusan akciós lesz, a módosított ár értékével.
                Ha   10 értéket adunk meg, akkor az ár akciós ára, 10 forint lesz, mindaddig, amíg nem 0 az értéke.">
            <b-form-input
                rows="4"
                v-model="sale"
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Képek"
            label-for="input-formatter"
            description="A tárgyhoz tartozó képek beállítása. Itt lehetséges feltölteni, illetve a galléria közül választani egyaránt.">
            <itemgallery-component :item_id="this.item_id"></itemgallery-component>
        </b-form-group>
        <br>
        <b-form-group class="mb-0"
                      label="Kategóriák"
                      label-for="input-formatter"
                      description="Az adott tárgy, mely kategóriákhoz tartozik.">
            <b-form-checkbox-group id="checkbox-group-2" v-model="checked" name="flavour-2"
                                   v-for="category in categories" v-bind:key="category.id">
                <b-form-checkbox :value="category.id">{{category.name}}</b-form-checkbox>
            </b-form-checkbox-group>
        </b-form-group>
        <br>
        <b-button variant="success" style="float:right" v-on:click=editItem()>Módosítás</b-button>
        <b-button variant="primary" href="/admin/items">Vissza</b-button>
        <div style="margin-bottom:10px"></div>
    </div>
</template>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import VueSelectImage from 'vue-select-image'

    Vue.use(BootstrapVue)
    Vue.use(VueSelectImage)
    export default {
        components: {
            VueSelectImage
        },
        props: ['item_id', 'item_name', 'item_description', 'item_price', 'item_sale', 'item_details', 'item_category'],
        data() {
            return {
                error: false,
                success: false,
                checked: this.item_category,
                successresponse: '',
                errors: [],
                details: this.item_details,
                name: this.item_name,
                price: this.item_price,
                description: this.item_description,
                sale: this.item_sale,
                category: this.item_category,
                emailtext: this.email,
                categories: [],
            }
        },
        mounted() {
            this.fetchCategories()
        },
        methods: {
            fetchCategories() {
                axios.get('/vue/api/categories').then((response) => {
                    this.categories = response.data;
                }).catch(error => {
                    this.errors = error.response.data
                });

            },
            editItem() {
                axios.post('/admin/item/' + this.item_id + '/edit', {
                    name: this.name,
                    description: this.description,
                    details: this.details,
                    price: this.price,
                    sale: this.sale,
                    category: this.checked,
                }).then((response) => {
                    this.success = true;
                    this.successresponse = response.data.success;
                }).catch(error => {
                    this.errors = error.response.data;
                    this.error = true;
                    this.success = false;
                });
                window.scrollTo(0, 0)
            },
        }
    }
</script>
