<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                {{successresponse}}
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                {{errors.error}}
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        <li>{{msg}}</li>
                    </div>
                </div>
            </b-alert>
        </div>
        <b-form-group
            class="mb-0"
            label="ID"
            label-for="input-formatter"
            description="A felhasználó egyedi azonosítója. Nem módosítható!">
            <b-form-input
                id="input-formatter"
                :placeholder=user_id
                disabled
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Felhasználónév"
            label-for="input-formatter"
            description="A felhasználó neve">
            <b-form-input
                id="input-formatter"
                v-model="user"
                disabled
            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Indok"
            label-for="input-formatter"
            description="A tiltás indoka">
            <b-form-input
                id="input-formatter"
                v-model="ban_reason"

            ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group
            class="mb-0"
            label="Tiltás tartama"
            label-for="input-formatter"
            description="Jelenleg eddig van tiltva a felhasználó. Módosításához kattints.">
            <datetime format="YYYY-MM-DD H:i:s" v-model="date"></datetime>
        </b-form-group>
        <br>
        <b-button variant="success" style="float:right" v-on:click=EditBan()>Módosítás</b-button>
        <b-button variant="primary" href="/admin/users/bans">Vissza</b-button>
        <div style="margin-bottom:10px"></div>

    </div>


</template>

<script>
    import axios from 'axios';
    import Vue from 'vue';
    import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import datetime from "vuejs-datetimepicker";

    Vue.use(BootstrapVue)
    export default {
        name: "DateTimePicker",
        components: {datetime},
        props: ['user_id', 'username', 'dateban', 'reason'],
        data() {
            return {
                error: false,
                success: false,
                banned_until: this.banned_until,
                successresponse: '',
                errors: [],
                date: this.dateban,
                user: this.username,
                ban_reason: this.reason,
                emailtext: this.email,
            }
        },
        methods: {
            EditBan() {
                axios.post('/admin/users/ban/' + this.user_id + '/manage', {
                    banned_until: this.date,
                    reason: this.ban_reason
                }).then((response) => {
                    this.success = true;
                    this.successresponse = response.data.success;
                }).catch(error => {
                    this.errors = error.response.data;
                    this.error = true;
                    this.success = false;
                });
                window.scrollTo(0, 0);
            },
        }
    }
</script>
