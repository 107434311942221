<template>
    <div style="width: 100%">
        <div>
            <b-alert variant="success" v-model="success" dismissible>
                {{ successresponse }}
            </b-alert>

            <b-alert variant="danger" v-model="error" dismissible>
                <template v-if="errors.message === 'Unauthenticated.'">
                    Jelenleg, csak regisztrált felhasználóknak érhető el ez a funkció!
                    <br>
                    A kosár használatához, regisztráció szükséges.
                </template>
                <div v-else v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{ msg }}
                    </div>
                </div>
            </b-alert>
        </div>
        <input type="text" class="form-control" placeholder="Keresés" v-model="filterName">
        <div class="row mt-5 mb-5">
            <div class="col-md-3" v-for="category in categories">
                <b-form-checkbox
                    v-model="category.checked"
                    name="checkbox-1"
                    unchecked-value="not_accepted">
                    {{ category.name }}
                </b-form-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 shop_box" style="margin-bottom: 10px" v-for="item in items">
                <div v-if="item.images && item.images.length > 0">
                    <img :src="item.images[0].src" class="thumbnail-img"
                         :alt="item.name"/>
                </div>
                <div v-else>
                    <img src="/images/noimage.png" class="thumbnail-img">
                </div>
                <span class="new-box" v-if="item.isNew">
                        <span class="new-label">Új</span>
                    </span>
                <span class="sale-box" v-if="item.sale > 0">
                            <span class="sale-label">Akció!</span>
                    </span>
                <div class="shop_desc">
                    <h3><a href="#"></a>{{ item.name }}</h3>
                    <p>{{ item.description }} </p>

                    <div style="margin-top: 10px">
                        <div v-if="item.sale > 0">
                            <span class="reducedfrom">{{ item.originalPrice }}</span>
                            <span class="actual" style="font-size:30px; color: green;">{{ item.formatPrice }}</span><br>
                        </div>
                        <div v-else>
                            <span class="actual" style="font-size:25px; ">{{ item.formatPrice }}</span><br>
                        </div>
                    </div>


                    <ul class="buttons">
                        <b-button variant="danger" @click="add(item.id)"><i class="fas fa-shopping-cart"></i></b-button>
                        <b-button variant="info" @click="redirect(item.id)">Megtekintés</b-button>
                        <div class="clear"></div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {

    data() {
        return {
            filterName: '',
            items: {},
            categories: [],
            success: false,
            errors: [],
            error: false,
            item_id: null,
            status: '',
            quantity: 1,
            successresponse: {},
        }
    },
    mounted() {
        this.fetchItems();
        this.fetchCategories();
    },
    methods: {
        fetchItems() {
            var selectedCategories =
                this.categories
                    .filter(category => {
                        return category.checked !== undefined && category.checked === true;
                    }).map(category => {
                    return "category[]=" + category.id;
                }).join('&');

            var filters = '';
            if (selectedCategories.length > 0) {
                filters += selectedCategories + '&';
            }
            if (this.filterName.length > 0) {
                filters += 'name=' + this.filterName + '&';
            }
            axios.get('/vue/api/items?' + filters).then((response) => {
                this.items = response.data;
                //console.log(this.status);

            }).catch(error => {
                this.errors = error.response.data;
                this.status = error.response.status;
            });
        },
        fetchCategories() {
            axios.get('/vue/api/categories?').then((r) => {
                this.categories = r.data;
            })
        },
        add(row) {
            axios.post('/item/shop', {
                item_id: row,
                quantity: 1,
            }).then((response) => {
                this.success = true;
                this.successresponse = response.data.success;
            }).catch(error => {
                this.errors = error.response.data;
                this.error = true;
                this.success = false;
            });
            window.scrollTo(0, 0);
        },
        redirect(row) {
            window.location = "/item/" + row;
        },
    },
    watch: {
        categories: {
            deep: true,
            handler() {
                this.fetchItems();
            }
        },
        filterName: {
            handler() {
                this.fetchItems();
            }
        },
    }
}
</script>
