<template>
    <div id="app">
        <div v-for="message in info" class="currency" v-html="message.message" :key="message.id">

        </div>
    </div>
</template>
<script>
    import axios from 'axios';

    export default {
        name: "ListAdminMessages",
        data() {
            return {
                info: null
            }
        },
        mounted() {
            axios
                .get('/vue/api/admin/messages')
                .then(response => (this.info = response.data))
        }
    }
</script>
<style scoped>
</style>
