<template>
    <div>
        <b-button v-b-modal.modal-used_image variant="primary">Képek megtekintése</b-button>
        <b-modal id="modal-used_image" size="xl" title="Feltöltött képek megtekintése"
                 ok-title="Mentés"
                 ok-variant="success"
                 cancel-title="Vissza"
                 cancel-variant="info"
                 @ok="save()">
            <div>
                <b-alert variant="success" v-model="success" dismissible>
                    {{successresponse}}
                </b-alert>
                <b-alert variant="danger" v-model="error" dismissible>
                    <div v-for="error in errors.errors">
                        <div v-for="msg in error">
                            {{msg}}
                        </div>
                    </div>
                </b-alert>
            </div>
            <div class="large-12 medium-12 small-12 cell mb-4">
                <label>Kép
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                    <b-btn variant="danger" v-if="Object.keys(initialSelected).length !== 0" varian="danger"
                           @click="deleteImage">Törlés
                    </b-btn>
                </label>
                <b-btn variant="info" v-on:click="submitFile()">Kép feltöltése</b-btn>
            </div>
            <vue-select-image :dataImages="dataImages"
                              :is-multiple="true"
                              @onselectmultipleimage="onSelectMultipleImage"
                              selected="1"
                              w=300
                              h=230>
            </vue-select-image>
        </b-modal>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        props: ['item_id'],
        data() {
            return {
                tomb: [],
                file: '',
                initialSelected: [],
                dataImages: [],
                dataImagesUsed: [],
                success: false,
                successresponse: "",
                errors: [],
                error: false,
            }
        },
        mounted() {
            this.fetchItems();
        },
        methods: {
            onSelectMultipleImage(e) {
                this.initialSelected = e;
            },
            save() {
                this.initialSelected.map((e) => {
                    return e.id
                });
                axios.post('/admin/item/' + this.item_id + '/edit', {
                    function: 'img',
                    image_id: this.initialSelected.map((e) => {
                        return e.id
                    }),
                }).then((response) => {
                    this.fetchItems()
                    this.success = true;
                    this.error = false;
                    this.successresponse = response.data.success;
                }).catch(error => {
                    this.errors = error.response.data;
                    this.success = false;
                    this.error = true;
                });
            },
            deleteImage() {
                axios.post('/admin/image_destroy', {
                    id: this.initialSelected.map((e) => {
                        return e.id
                    }),
                }).then((response) => {
                    this.fetchItems()
                    this.success = true;
                    this.error = false;
                    this.successresponse = response.data.deleted;
                }).catch(error => {
                    this.errors = error.response.data;
                    this.success = false;
                    this.error = true;
                });
            },
            fetchItems() {
                axios.get('/vue/api/admin/gallery').then((response) => {
                    this.dataImages = response.data;
                })
            },
            submitFile() {
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post('/admin/image_upload',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response) => {
                    this.fetchItems();
                    this.success = true;
                    this.error = false;
                    this.successresponse = response.data.success;
                })
                    .catch(error => {
                        this.errors = error.response.data;
                        this.success = false;
                        this.error = true;
                    });
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0];
            }
        }
    }
</script>
