<template>
    <div style="margin-left: 30px; margin-right: 30px; margin-top: 20px">
        <div>
            <b-alert v-if=response variant="success" show dismissible>
                Sikeresen módosítottad a profilodat.
            </b-alert>
            <b-alert v-else-if="errors" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{ msg }}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-form-group
            class="mb-0"
            type="input"
            label="ID"
            label-for="input-formatter"
            description="A felhasználód egyedi azonosítója. Nem módosítható.">
            <b-form-input
                id="input-formatter"
                :placeholder="user.id.toString()"
                disabled
            ></b-form-input>
        </b-form-group>

        <br>

        <b-form-group
            class="mb-0"
            label="Nickname"
            label-for="input-formatter"
            description="A jelenlegi ,,nickname''-d. jelenleg nem módosítható.">
            <b-form-input
                id="input-formatter"
                :value=user.name
                disabled
            ></b-form-input>
        </b-form-group>

        <br>

        <b-form-group
            class="mb-0"
            label="E-mail cím"
            label-for="input-formatter"
            description="A jelenlegi e-mail címed.">
            <b-form-input
                id="input-formatter"
                v-model="email"
            ></b-form-input>
        </b-form-group>

        <br>

        <b-form-group
            class="mb-0"
            label="Új jelszó"
            label-for="input-formatter"
            description="Új jelszó megadása">
            <b-form-input
                id="input-formatter"
                v-model="password_1"
            ></b-form-input>
        </b-form-group>

        <br>

        <b-form-group
            class="mb-0"
            label="Jelszó megerősítése"
            label-for="input-formatter"
            description="Jelszó megerősítése">
            <b-form-input
                id="input-formatter"
                v-model="password_2"
            ></b-form-input>
        </b-form-group>
        <br>

        <b-button variant="success" style="float:right" v-on:click=editUser()>Módosítás</b-button>

        <b-button variant="primary" href="/">Vissza</b-button>
        <div style="margin-bottom:10px"></div>

    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSelectImage from 'vue-select-image'

Vue.use(BootstrapVue)
Vue.use(VueSelectImage)


export default {
    components: {
        VueSelectImage
    },
    props: ['user', 'user.id'],
    data() {
        return {
            password_1: null,
            password_2: null,
            email: this.user.email,
            response: null,
            successresponse: '',
            errors: null,
        }
    },
    mounted() {

    },
    methods: {
        editUser() {
            axios.put('/edit_profile', {
                email: this.email,
                password_1: this.password_1,
                password_2: this.password_2,
            }).then(
                response => {
                    this.response = response;
                    this.errors = null;
                }
            ).catch(error => {
                this.errors = error.response.data;
                this.response = null;
            });
            window.scrollTo(0, 0);
        },
    }
}
</script>
