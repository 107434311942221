<template>
    <b-container fluid>
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                {{ successresponse }}
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        {{ msg }}
                    </div>
                </div>
            </b-alert>
        </div>
        <b-row>
            <b-col lg="6" class="my-1">
                <b-form-group
                    label="Rendezés"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-select v-model="sortBy" id="sortBySelect" :options="sortOptions" class="w-75">
                            <template v-slot:first>
                                <option value="">-- semmi --</option>
                            </template>
                        </b-form-select>
                        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                            <option :value="false">Növekvő</option>
                            <option :value="true">Csökkenő</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
                <b-form-group
                    label="Szűrő"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input
                            v-model="filter"
                            type="search"
                            id="filterInput"
                            placeholder=""
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Tisztítás</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col sm="5" md="6" class="my-1">
                <b-form-group
                    label="Adat per oldal"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0">
                    <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-table
            show-empty
            small
            stacked="md"
            :items="users"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered">
            <template v-slot:cell(actions)="row">

            </template>
            <template v-slot:cell(actions)="row" >
                <div size="sm" @click="editCategory(row.item.id)" class="mr-1" variant="info">
                    {{ row.item.price * row.item.items[0].quantity }}
                </div>

            </template>

            <template v-slot:cell(status)="row">
                <div>
                    <b-form-select v-model="row.item.order_status" :options="options" size="sm" class="mt-3"
                                   @change="changeStatus(row.item.id , row.item.order_status)"></b-form-select>
                </div>
            </template>
        </b-table>

        <b-col sm="7" md="6" class="my-1">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
            ></b-pagination>
        </b-col>
    </b-container>
</template>


<script>
import axios from 'axios';
import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
export default {
    data() {
        return {
            success: false,
            error: false,
            users: [],
            fields: [
                {key: 'items.0.item_id', label: 'Tárgy azonosítója', sortable: true, sortDirection: 'desc'},
                {key: 'items.0.item.name', label: 'Tárgy neve', sortable: true, class: 'text-center'},
                {key: 'items.0.item.formatPrice', label: 'Ár', sortable: true, class: 'text-center'},
                {key: 'items.0.quantity', label: 'Darabszám', sortable: true, class: 'text-center'},
                {key: 'actions', label: 'Összesen'},
                {key: 'created_at', label: 'Vásárlás dátuma', sortable: true, class: 'datetime'},

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            options: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        }
    },
    mounted() {
        this.fetchOrderStatus()
        this.fetchOrders()

        // Set the initial number of items
        this.totalRows = this.users.length;
    },
    methods: {

        fetchOrderStatus() {
            axios.get('/vue/api/admin/order_status').then((response) => {
                this.options = response.data.map((item) => {
                    return {text: item.status, value: item.id};
                });
            }).catch(error => {
                console.log(error);
            });
        },

        fetchOrders() {
            axios.get('/vue/api/admin/orders/')
                .then(response => {
                    this.users = response.data
                });
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        changeStatus(orderId, statusId) {
            axios.post('/admin/orders/' + orderId + '/update', {
                order_status: statusId,
            }).then((response) => {
                this.success = true;
                this.successresponse = response.data.success;
            }).catch(error => {
                if (error.response !== undefined && error.response.data !== undefined) {
                    this.errors = error.response.data;
                }
                this.error = true;
                this.success = false;
            });
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkItem(id) {
            window.location.href = `/item/${id}`
        },
    }
}
</script>

