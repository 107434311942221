/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(VueSelectImage)
import VueSelectImage from 'vue-select-image'


window.Vue = require('vue');


Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// add stylesheet
require('vue-select-image/dist/vue-select-image.css')
//Vue.config.productionTip = false

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('pagination', require('laravel-vue-pagination'));


Vue.component('datetimepicker-component', require('./components/DateTimePicker').default);
Vue.component('richtexteditor-component', require('./components/RichTextEditor').default);
Vue.component('listadminmessages-component', require('./components/ListAdminMessages').default);


//Admin
Vue.component('ban-component', require('./components/admin/users/bans/BanUser').default);
Vue.component('useredit-component', require('./components/admin/users/edit/EditUser').default);
Vue.component('banlist-component', require('./components/admin/users/bans/BansList').default);
Vue.component('editban-component', require('./components/admin/users/bans/EditBan').default);
Vue.component('itemgallery-component', require('./components/admin/items/ItemGallery').default);


Vue.component('itemlistadmin-component', require('./components/admin/items/ItemListAdmin').default);
Vue.component('edititem-component', require('./components/admin/items/EditItem').default);
Vue.component('createitem-component', require('./components/admin/items/CreateItem').default);
Vue.component('categoryitem-component', require('./components/admin/items/CategoryItem').default);

Vue.component('itemlistadmin-component', require('./components/admin/items/ItemListAdmin').default);
Vue.component('edititem-component', require('./components/admin/items/EditItem').default);
Vue.component('createitem-component', require('./components/admin/items/CreateItem').default);
Vue.component('usersmanage-component', require('./components/UsersManage.vue').default);


Vue.component('ordersmanage-component', require('./components/OrdersManage.vue').default);
Vue.component('checkorderinfos-component', require('./components/CheckOrderInfos.vue').default);

Vue.component('editcategory-component', require('./components/admin/category/EditCategory').default);
Vue.component('createcategory-component', require('./components/admin/category/CreateCategory').default);
Vue.component('categorylist-component', require('./components/admin/category/CategoryList').default);

//Kuponok
Vue.component('editcoupon-component', require('./components/admin/coupons/EditCoupon').default);
Vue.component('createcoupon-component', require('./components/admin/coupons/CreateCoupon').default);
Vue.component('couponlist-component', require('./components/admin/coupons/CouponList').default);



Vue.component('itemlistadmin-component', require('./components/admin/items/ItemListAdmin').default);
Vue.component('edititem-component', require('./components/admin/items/EditItem').default);
Vue.component('createitem-component', require('./components/admin/items/CreateItem').default);
Vue.component('categoryitem-component', require('./components/admin/items/CategoryItem').default);

//Item (user)
Vue.component('itemlist-component', require('./components/users/cart/items/ItemList').default);
Vue.component('iteminspect-component', require('./components/users/cart/items/ItemInspect').default);

//Kosár (user)
Vue.component('checkout-component', require('./components/users/cart/checkout/Checkout').default);
Vue.component('wishlist-component', require('./components/users/cart/wishlist/wishlist').default);
Vue.component('payment-component', require('./components/users/cart/checkout/Payment').default);

//Ország template
Vue.component('countries-component', require('./components/users/cart/checkout/layout/Countries').default);

//Profile
Vue.component('profile-component', require('./components/users/profile/Profile').default);





/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',

});


