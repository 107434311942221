<template>
    <div>
        <div>
            <b-alert v-if=success variant="success" show dismissible>
                {{ successresponse }}
            </b-alert>
            <b-alert v-else-if="error" variant="danger" show dismissible>
                <div v-for="error in errors.errors">
                    <div v-for="msg in error">
                        <li>{{ msg }}</li>
                    </div>
                </div>
            </b-alert>
        </div>
        <table id="cart" class="table table-hover table-condensed">
            <thead>
            <tr>
                <th style="width:50%">Termék</th>
                <th style="width:10%">Ár / db</th>
                <th style="width:8%">Mennyiség</th>
                <th style="width:22%" class="text-center">Összesen</th>
                <th style="width:10%">Műveletek</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in items">
                <td data-th="Product">
                    <div class="row">
                        <img :src="item.item.mainImage.src"
                             style="max-width:75px; height: 75px"
                             alt="..."
                             class="img-responsive"/>
                        <div class="col-sm-10">
                            <h4 class="nomargin">{{ item.item.name }}</h4>
                            <p>{{ item.item.description }}</p>
                        </div>
                    </div>
                </td>

                <td data-th="Price" v-if="item.length > 0">
                    {{ formatPrice(item.item.endPrice) }} Ft
                </td>
                <td data-th="Quantity">

                    <b-form-spinbutton v-model="item.quantity" inline name="quantity"
                                       v-on:change="changeQuantity(item.item_id, item.quantity)"></b-form-spinbutton>

                </td>
                <td data-th="Subtotal"
                    class="text-center">
                    {{ formatPrice(item.quantity) }}
                </td>
                <td data-th="Subtotal"
                    class="text-center">
                    {{ formatPrice(item.item.endPrice * item.quantity) }} Ft
                </td>
                <td class="actions" data-th="">
                    <a class="btn btn-danger btn-sm" style="color:white"><i
                        class="fa fa-trash" @click="deleteItem(item.item.id)"></i></a>

                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td><a href="/shop" class="btn btn-info"><i class="fa fa-angle-left"></i>
                    Vásárlás folytatása</a>
                </td>


                <td colspan="2" class="hidden-xs"></td>
                <td class="hidden-xs text-center" v-if="items.length > 0 "><strong>
                    {{ formatPrice(totalItem) }} Ft
                </strong></td>
                <td v-if="items.length > 0">
                    <a href="/payment"
                       class="btn btn-success ">Tovább a fizetéshez <i class="fa fa-angle-right"></i></a></td>
                <td v-else>
                    <a href="/item/payment" disabled
                       class="btn btn-danger ">Üres a kosarad :( <i class="fa fa-angle-right"></i></a>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import axios from "axios";

export default {
    computed: {
        totalItem: function () {
            let sum = 0;
            for (let i = 0; i < this.items.length; i++) {
                sum += (parseFloat(this.items[i].item.endPrice) * parseFloat(this.items[i].quantity));
            }

            return sum;
        }
    },
    data() {
        return {
            items: {},
            success: false,
            errors: [],
            value: 0,
            total: 0,
            error: false,
            item_id: null,
            successresponse: {},
        }
    },
    mounted() {
        this.fetchItems();
    },
    methods: {
        fetchItems() {
            axios.get('/vue/api/checkout?').then((response) => {
                this.items = response.data;
            }).catch(error => {
                this.errors = error.response.data
            });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        changeQuantity(item_id, quantity) {
            axios.post('/checkout', {
                item_id: item_id,
                quantity: quantity,
            }).then((response) => {
                this.success = true;
                this.error = false;
                this.successresponse = response.data.success;
            }).catch(error => {
                this.errors = error.response.data;
                this.success = false;
                this.error = true;
            });
        },
        deleteItem(id) {
            axios.post('/item_delete', {
                item_id: id,
            }).then((response) => {
                this.fetchItems();
                this.success = true;
                this.error = false;
                this.successresponse = response.data.success;
            }).catch(error => {
                this.errors = error.response.data;
                this.success = false;
                this.error = true;
            });
        },
    },
}
</script>
